import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import clsx from 'clsx';

import SEO from '../components/SEO';
import MainLayout from '../layouts/MainLayout';

import './WindowTypeTemplate.styl';

import isoT from '../../contents/images/app/iso-t.svg';
import isoP from '../../contents/images/app/iso-p.svg';
import isoGrad from '../../contents/images/app/Isolation-graduation.svg';
import air from '../../contents/images/app/air.png';

import ptz from '../../contents/images/app/ptz.svg';
import tva from '../../contents/images/app/tva.svg';
import primeEner from '../../contents/images/app/prime-energie.svg';
import primRenov from '../../contents/images/app/maprimerenov.png';

import qualBois from '../../contents/images/app/qual-bois.png';
import qualPvc from '../../contents/images/app/qual-pvc.png';
import qualAlu from '../../contents/images/app/qual-alu.png';
import qualAluBc from '../../contents/images/app/qual-alu-bc.png';

function WindowTypesTemplate({
    location,
    pageContext: {
        accessoriesLink,
        details,
        label,
        labelPlus,
        material,
        priceLink,
        windowType,
    },
}) {
    const { list, title } = details;

    // test

    const getLabel = () =>
        `${label} en ${material.toUpperCase()}${
            labelPlus !== null ? ` avec ${labelPlus} ` : ''
        }`;

    const getTitle = () =>
        label === 'Châssis abattants'
            ? `Tous nos châssis abattants en ${material.toUpperCase()} ${
                  labelPlus !== null ? `avec ${labelPlus} ` : ''
              } sont équipés de :`
            : `Toutes nos ${label.toLocaleLowerCase()} en ${material.toUpperCase()} ${
                  labelPlus !== null ? `avec ${labelPlus} ` : ''
              } sont équipées de :`;

    const getQualityImage = () => {
        switch (material) {
            case 'pvc':
                if (windowType === 'bc') {
                    return qualAlu;
                }
                return qualPvc;
            case 'bois':
                return qualBois;

            case 'aluminium': {
                if (windowType === 'bc') {
                    return qualAluBc;
                }
                return qualAlu;
            }

            default:
                return qualAlu;
        }
    };

    const getUwcolor = (value) => {
        if (value === 1.2) {
            return 'uw1';
        }
        if (value > 1.2 && value < 1.8) {
            return 'uw2';
        }
        return 'uw3';
    };

    return (
        <MainLayout
            headerOptions={{
                backButton: true,
            }}
        >
            <SEO
                title='Brico App'
                location={location}
                description='Brico App'
            />
            <div className='content window-type-detail container'>
                <div className='white-bloc'>
                    <div className='top'>
                        <div className='left'>
                            <div className='white-bloc-title txt-red'>
                                {getLabel()}
                            </div>
                            <div className='white-bloc-subtitle'>
                                {title !== null ? title : getTitle()}
                            </div>
                            <div className='list'>
                                {list.map((item) => (
                                    <div
                                        key={item}
                                        className='list-item'
                                    >{`- ${item}`}</div>
                                ))}
                            </div>
                        </div>

                        <div className='right'>
                            <div className='plus-bloc'>
                                <div className='plus-bloc-title txt-red'>
                                    + de performance
                                </div>
                                <div className='group'>
                                    <div className='perf-left'>
                                        <div className='picto iso-t'>
                                            <img src={isoT} alt='' />
                                            <div
                                                className={clsx(
                                                    'value',
                                                    getUwcolor(details.perf.uw),
                                                )}
                                            >
                                                Uw = {details.perf.uw}
                                            </div>
                                        </div>
                                        <div className='picto iso-p'>
                                            <img src={isoP} alt='' />
                                            <div className='value'>
                                                {details.perf.db} dB
                                            </div>
                                        </div>
                                    </div>
                                    <div className='perf-right'>
                                        <img src={isoGrad} alt='iso grad' />
                                        <img
                                            src={air}
                                            alt='air'
                                            className='air'
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='plus-bloc'>
                                <div className='plus-bloc-title txt-red'>
                                    + de qualité
                                </div>
                                <div className='img-container'>
                                    <img src={getQualityImage()} alt='' />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='bottom'>
                        <Link to={priceLink} className='link'>
                            <i className='icon icon-focus' />
                            <span>Tarifs des fenêtres</span>
                        </Link>
                    </div>
                </div>

                <div className='white-bloc'>
                    <div className='white-bloc-title txt-red'>Accessoires</div>
                    <div className='white-bloc-subtitle'>
                        Nos fenêtres sont équipées d’un cadre permettant d’y
                        clipser différents accessoires afin de s’adapter à
                        toutes les poses (tapées, élargisseurs, habillages,
                        champlats…).
                    </div>
                    <div className='bottom'>
                        <Link to={accessoriesLink} className='link'>
                            <i className='icon icon-focus' />
                            <span>Tarifs des accessoires</span>
                        </Link>
                    </div>
                </div>

                <div className='white-bloc border'>
                    <div className='top'>
                        <div className='left'>
                            <div className='white-bloc-title txt-green'>
                                <i className='icon icon-infos' />
                                <span>Aides financières </span>
                            </div>
                            <div className='white-bloc-subtitle'>
                                Pour l’installation de vos fenêtres par un
                                artisan certifié RGE.
                            </div>
                            <div>
                                - Toutes nos fenêtres sont éligibles aux aides
                                financières gouvernementales.
                            </div>
                        </div>
                        <div className='right'>
                            <div className='help-picto'>
                                <img src={tva} alt='tva' />
                            </div>
                            <div className='help-picto'>
                                <img src={ptz} alt='ptz' />
                            </div>
                            <div className='help-picto'>
                                <img src={primeEner} alt='prime energie' />
                            </div>
                            <div className='help-picto'>
                                <img src={primRenov} alt='ma prime renov' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>
    );
}

WindowTypesTemplate.propTypes = {
    location: PropTypes.object.isRequired,
    pageContext: PropTypes.shape({
        accessoriesLink: PropTypes.string.isRequired,
        details: PropTypes.object.isRequired,
        label: PropTypes.string.isRequired,
        labelPlus: PropTypes.string,
        material: PropTypes.string.isRequired,
        priceLink: PropTypes.string.isRequired,
        windowType: PropTypes.string.isRequired,
    }).isRequired,
};

export default WindowTypesTemplate;
